import { QuestionAccordion } from 'apps/bridge/src/components/Faq/QuestionAccordion';

export function FaqSidebar() {
  return (
    <div className="flex w-full flex-col p-4 pl-6 font-sans lg:w-[420px] lg:border-l lg:border-sidebar-border">
      <span className="mt-6 mb-8 font-mono text-base font-medium uppercase text-stone-400">
        Frequently asked questions
      </span>
      <QuestionAccordion
        question="Can I still use the bridge.base.org?"
        answer="No, but the legacy bridge has been moved to v1-basebridge.app. Which is the original bridge for base"
      />
      <QuestionAccordion
        question="I used the bridge.base.org in the past, how do I find my deposit or withdrawal?"
        answer="Any of the bridges including the legacy bridge on v1-basebridge.app will have information on all your past bridges as they share the same bridging contracts."
      />
      <QuestionAccordion
        question="Why will the bridge.base.org no longer be updated in the future?"
        answer="Base is committed to decentralization and the Superchain vision. Leveraging the community to bootstrap the Superchain bridges is a step in that direction; increasing censorship resistance and decentralization."
      />
      <QuestionAccordion
        question="Who operates Brid.gg?"
        answer=' Brid.gg is operated by third parties, not by Coinbase Technologies, Inc. ("Coinbase"). Coinbase does not control, operate, or assume any responsibility for the performance of these external platforms. Before using  Brid.gg, you may be required to agree to their terms and conditions. We strongly recommend you review these terms carefully, as well as any privacy policies, to understand your rights and obligations. The integration or inclusion of  Brid.gg does not imply an endorsement or recommendation of any bridge by Coinbase.'
      />
      <QuestionAccordion
        question="What if I have a question, issue or problem?"
        answer={
          <>
            The{' '}
            <a href="https://t.me/BaseBridge_io" rel='noreferrer' target='__blank' className="underline">
              Official Base Telegram
            </a>{' '}
            community is available around the clock for general questions.
          </>
        }
      />
    </div>
  );
}
